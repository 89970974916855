
import { Action, Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { Mandate } from "@/store/modules/mandate.store";
import { bus } from "@/main";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

@Component({
    components: {
        Spinner: () => import("@/components/general/spinner.vue"),
        SignMandateModal: () => import("@/components/modals/sign-mandate-modal.vue"),
        VuePdfEmbed,
    },
})
export default class MandatePreview extends Vue {
    @Action("mandate/read") readMandate!: (payload: number) => Promise<Mandate>;
    @Action("activity/read") readActivity!: (payload: { id: number }) => Promise<Activity>;
    @Getter("activity/viewing") activity!: Activity;

    mandate_id = Number(this.$route.params.mandateId);

    activity_id = Number(this.$route.params.id);

    downloaded = false;

    mandate: Mandate | null = null;

    loading = false;

    error = false;

    bus = bus;

    get name() {
        return `Bemiddelingsovereenkomst - ${this.activity.name}`;
    }

    get previewUrl() {
        if (!this.mandate) {
            return "";
        }

        return `${process.env.VUE_APP_API_URL}/mandates/${this.mandate.id}/preview#toolbar=0`;
    }

    downloadPdf() {
        if (!this.mandate) {
            return "";
        }

        this.downloaded = true;

        this.download_file(`${process.env.VUE_APP_API_URL}/mandates/${this.mandate.id}/preview`, this.$t(`views.mandate.type.${this.mandate.details.type}`) + ` (${this.activity.property_address.zip} ${this.activity.property_address.city} - ${this.activity.property_address.street} ${this.activity.property_address.number}).pdf`);
    }

    download_file(fileURL: string, fileName: string) {
        //Create XMLHTTP Request.
        var req = new XMLHttpRequest();
        req.open("GET", fileURL, true);
        req.responseType = "blob";
        req.onload = function () {
            var blob = new Blob([req.response], { type: "application/octetstream" });

            var url = window.URL || window.webkitURL;
            var link = url.createObjectURL(blob);
            var a = document.createElement("a");
            a.setAttribute("download", fileName);
            a.setAttribute("href", link);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        };
        req.send();
    }

    async mounted() {
        this.loading = true;
        if (!this.activity) {
            await this.readActivity({ id: this.activity_id });
        }

        this.mandate = await this.readMandate(this.mandate_id);
    }

    handlePdfLoaded(event: any) {}
    handlePdfRendered(event: any) {
        this.loading = false;
        this.error = false;
    }
    handleRenderingPdfFailed(event: any) {
        this.$toast.open({ message: "Er ging iets mis tijdens het renderen van de PDF." as string, type: "error" });
        this.loading = false;
        this.error = true;
    }
    handleLoadingPdfFailed(event: any) {
        this.$toast.open({ message: "Er ging iets mis tijdens het laden van de PDF." as string, type: "error" });
        this.loading = false;
        this.error = true;
    }
}
